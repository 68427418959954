import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import wxError from './views/wxError.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import global_msg from '@/utils/global.js'

// swiper
import VueAwesomeSwiper from 'vue-awesome-swiper'

// import style
import 'swiper/swiper-bundle.css'
import "./assets/fonts/iconfont.css";
import "./assets/css/css.css";

import { getUrlKey,parseTime } from '@/utils/utils';
Vue.prototype.getUrlKey=getUrlKey;
Vue.prototype.parseTime=parseTime;

Vue.use(ElementUI);
Vue.use(VueAwesomeSwiper)
Vue.config.productionTip = false

Vue.prototype.$global_msg = global_msg;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

// import axios from 'axios'
// var ua = navigator.userAgent.toLowerCase();
// var isWeixin = ua.indexOf('micromessenger') != -1;
// if(!isWeixin){
//   axios.get('/axiosapi/cityjson?ie=utf-8',(req, res) => {
//     // res.header("Access-Control-Allow-Origin", "*")
//   }).then(res => {
//     let ip = res.data;
//     // console.log(ip,ip.indexOf("127.0.0.1") == -1)
//     if(ip.indexOf("192.168.") == -1 && ip.indexOf("127.0.0.1") == -1 && ip.indexOf("222.187.23.162") == -1){
//       new Vue({
//         router,
//         render: h => h(wxError)
//       }).$mount('#app')
//     }
//   })
// }


// var ua = navigator.userAgent.toLowerCase();
// var isWeixin = ua.indexOf('micromessenger') != -1;
// //判断是不是微信登陆
// if(!isWeixin){
//   //不是微信访问
//   if(window.location.host.indexOf("192.168.") > -1 || window.location.host.indexOf("localhost") > -1){
//     //是内网环境
//   }
//   else {
//     new Vue({
//       router,
//       render: h => h(wxError)
//     }).$mount('#app')
//   }
// }